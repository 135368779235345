import React from 'react';
import HeadingSection from '../components/HeadingSection';
import Card from '../components/Card';
import { 
  Content,
  Wrapper
 } from '../styles/global';


const Confirmation = () => {
  return (
    <Wrapper>
      <Card>
        <Content>
          <HeadingSection 
            heading={'Tack för din feedback'}
            paragraph={'Tack för att du tagit dig tid att berätta för oss hur du upplevde vår tjänst.'}
          />
          &nbsp;
        </Content>
      </Card>
    </Wrapper>
  );
};

export default Confirmation;