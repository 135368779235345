import React from 'react';
import { StyledH2, StyledParagraph } from '../styles/typography';
import { Content } from '../styles/global';
import BtnAction from './BtnAction';

const ErrorComponent = ({ serverError }) => {
  
  const reloadPage = () => {
    window.location.reload();
  }

  return (
    <>
    <Content>
      <StyledH2>Ojdå ...</StyledH2>
        <StyledParagraph>{serverError}</StyledParagraph>
    </Content>
    <BtnAction
      onClick={() => reloadPage()} 
      revertStyle
    >
      Tillbaka
    </BtnAction>
    </>
  )
}
  
export default ErrorComponent