import React from 'react';
import { StyledH2, StyledParagraph } from '../styles/typography';

const HeadingSection = ({ heading, paragraph }) => {
  return (
    <>
      <StyledH2>{heading}</StyledH2>
      <StyledParagraph>{paragraph}</StyledParagraph>
    </>
  )
}
  
export default HeadingSection