import styled from 'styled-components';
import { StyledParagraph } from './typography';

export const ErrorMessage = styled.p`
  position: absolute;
  top: 42px;
  color: ${({ theme }) => theme.error};
  font-weight: ${({ theme }) => theme.fontWeights[1]};
  font-size: ${({ theme }) => theme.fontSizes[0]};
`;

export const Wrapper = styled.main`
  max-width: 650px;
  margin: 40px auto;
`;

export const Content = styled.div`
  padding: 0 ${({ theme }) => theme.space[2]};
`

export const ServerError = styled(StyledParagraph)`
  padding-bottom: ${({ theme }) => theme.space[2]};
  font-weight: ${({ theme }) => theme.fontWeights[1]};
  font-size: ${({ theme }) => theme.fontSizes[3]};
  line-height: 1.5;
  margin: 0;
`