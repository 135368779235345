import React from 'react';
import Review from './pages/Review'
import Confirmation from './pages/Confirmation'
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

const App = () => {
  return (
    <Router>
      <Switch>
        <Route exact path='/confirmation' component={Confirmation} />
        <Route path='/' component={Review} />
      </Switch>
    </Router>
  )};

export default App;