
import React from 'react';
import styled from 'styled-components';

const StyledButton = styled.button`
  background: ${props => (props.revertColor ? props.theme.buttonGrey : props.theme.gradient)};
  font-size: ${({ theme }) => theme.fontSizes[2]};
  text-transform: uppercase;
  font-weight: ${({ theme }) => theme.fontWeights[1]};
  color: ${props => (props.revertColor ? props.theme.secondary : props.theme.primary)};
  border: none;
  box-sizing: border-box;
  outline: none;
  cursor: pointer;
  border-radius: 0 0 ${({ theme }) => theme.borderRadius.form} ${({ theme }) => theme.borderRadius.form};
  height: 60px;
  width: 100%;
  margin-top: ${({ theme }) => theme.space[3]};
  &:disabled {
    opacity: 0.7;
    cursor: default;
  }
`

const BtnAction = ({ children, revertStyle, onClick }) => (
    <StyledButton
      revertColor={revertStyle ? true : false}
      onClick={onClick}
    >
      {children}
    </StyledButton>
);

export default BtnAction;