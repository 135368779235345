import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom'
import { createGlobalStyle } from 'styled-components';
import { ThemeProvider } from 'styled-components';
import theme from './styles/theme';
require('typeface-source-sans-pro')

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    background: #F3F3F3;
    font-family: Source Sans Pro, Sans-Serif;
  }
  textarea {
    font-family: Source Sans Pro, Sans-Serif;
  }
  h1, h2, h3, h4, h5, p, div {
    margin: 0;
    padding: 0;
  }
`;

ReactDOM.render(
  <React.StrictMode>
        <BrowserRouter>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <App />
      </ThemeProvider>
      </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
