import React, { useState, useEffect } from 'react';
import axios from 'axios'
import styled from 'styled-components';
import Rate from 'rc-rate';
import CircleLoader from '../components/CircleLoader';
import ErrorComponent from '../components/ErrorComponent';
import HeadingSection from '../components/HeadingSection';
import Card from '../components/Card';
import BtnAction from '../components/BtnAction';
import { useHistory } from "react-router-dom";
import 'rc-rate/assets/index.css';
import { 
  Content,
  Wrapper
 } from '../styles/global';
import { useLocation } from "react-router-dom";

const StyledRate = styled(Rate)`
  margin-top: ${({ theme }) => theme.space[2]};
  &.rc-rate {
    font-size: ${({ size }) => size}px;
    li {
      div {
        outline: none;
      }
    }
  }
`

const Review = () => {
  let location = useLocation();
  let history = useHistory();

  const [serverError, setServerError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [rating, setRating] = useState(false);
  const [url, setUrl] = useState(false);

  const onChange = (v) => {
    setRating(v)
  }


  const sendRating = async () => {
    setIsLoading(true);
    const apiClient = axios.create({
      baseURL: 'https://duty24.herokuapp.com',
      responseType: 'json',
    })
  
    try {
      let payload = {
        orderId: url,
        rating: rating,
      }
      const response = await apiClient.post(
        '/orders/setRating', payload
      )
      const data = response
      if (data.status === 200) {
        setIsLoading(false);
        history.push("/confirmation");
      }
    } catch(error) {
      setServerError(error.response.data.error)
      setIsLoading(false);
    }
  }

  useEffect(() => {
    let url = location.pathname
    let code = url.replace('/','');
    setUrl(code)
  })

  return (
      <Wrapper>
        <Card>
        {serverError ?
          <ErrorComponent serverError={serverError} />
        : 
        <>
          <Content>
            <HeadingSection 
              heading={'Vi älskar feedback'}
              paragraph={'Betygsätt gärna din upplevelse av 24duty här. Din åsikt hjälper oss i vår strävan att ständigt förbättra oss. '}
            />
            <StyledRate
              size="34"
              onChange={onChange}
            />
          </Content>

          {isLoading ? 
            <CircleLoader />
          :
            <BtnAction type="submit" onClick={sendRating}>Skicka</BtnAction>
          }
        </>
        }
        </Card>
      </Wrapper>
  );
};

export default Review;